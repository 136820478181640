<script setup>


const refSlideControl = ref();
const refSlideNavigation = ref();

const searcher = useSearch();

const props = defineProps({
  title: String,
  cars: Array,
  href: String,
  search: String,
  isMoto: Boolean,
  isCategoryPage: Boolean,
  isCustomUrl: Boolean
});

const cars = ref([]);
const carsLoaded = ref(false)

const updateSlideNumber = (slideNumber) => {
  refSlideNavigation.value.setSlide(slideNumber);
}

const scrollToSlide = (slide) => {
  refSlideControl.value.scrollToSlide(slide);
}

const goToSearch = () => {
  navigateTo({path: '/wyszukaj?'+props.search }, {external: true})
}

const linkToCollection = computed(() => {
  return props.isCustomUrl ? props.href : { name: 'collections', params: { slug: props.href } }
})

onMounted(async () => {
  const loadedCars = await searcher.search(props.search, 1, false, false, false);
  cars.value = loadedCars?.data
  carsLoaded.value = true;
})

</script>

<template>
  <div
    :class="{'border-y border-celadon border-solid pb-6 pt-8' : isCategoryPage}">
    <div class="flex justify-between items-center">
      <div>
        <VectorVehisHorizontal v-if="isMoto" class="h-5 w-32 mt-4"/>
        <SubContentHeader :class="{'!text-left':isMoto}" class="text-left sm:text-center">{{ props.title }}</SubContentHeader>
      </div>
      <div class="hidden lg:block">
        <ButtonRounded color="transparent" :href="{name: 'collections', params: {slug: props.href}}" fit>Więcej modeli</ButtonRounded>
      </div>
    </div>
    <SkeletonCollection v-if="!carsLoaded" />
    <SlideControl v-if="carsLoaded"
                  ref="refSlideControl"
                  class="-mt-4"
                  @swipeEnd="(slideNumber) => updateSlideNumber(slideNumber)"
    >
      <template v-for="car in cars">
        <SlideItem>
          <CarTile :car="car" :search-query="props.search"/>
        </SlideItem>
      </template>
    </SlideControl>
    <SlideNavigation ref="refSlideNavigation"
                     class="-mt-4"
                     :items=cars?.length
                     @changed="(slide) => scrollToSlide(slide)"
    />
    <div class="lg:hidden flex justify-center py-6">
      <ButtonRounded color="transparent" :href="linkToCollection" fit>Więcej modeli</ButtonRounded>
    </div>
  </div>
</template>
